<script>
    export default {
        name: 'tab-portfolio',
        data() {
            return {
                currentTab: 0,
                playlist: [],
                trackIds: []
            }
        },
        created() {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if(params.playlist){
                localStorage.setItem('playlist', decodeURI(params.playlist))
            }
            let obj = JSON.parse(localStorage.getItem('playlist'))
            if (obj) {
                this.playlist = obj.playlist
                this.trackIds = obj.trackIds
            }

        },
        methods: {
            shuffle() {
                this.playlist = _.shuffle(this.playlist)
            },
            addToPlayList(url, name, id) {
                if (this.trackIds.indexOf(id) !== -1) return false;
                this.trackIds.push(id)
                this.playlist.push({
                    name: name,
                    url: url,
                    id: id
                })
                localStorage.setItem('playlist', JSON.stringify({playlist: this.playlist, trackIds: this.trackIds}))
            },
            deleteTrack(i) {
                this.trackIds.splice(this.trackIds.indexOf(this.playlist[i].id), 1)
                this.playlist.splice(i, 1)
                localStorage.setItem('playlist', JSON.stringify({playlist: this.playlist, trackIds: this.trackIds}))
            },
            upTrack(i) {
                this.playlist.splice(i - 1, 0, this.playlist.splice(i, 1)[0]);
                localStorage.setItem('playlist', JSON.stringify({playlist: this.playlist, trackIds: this.trackIds}))
            },
            downTrack(i) {
                this.playlist.splice(i + 1, 0, this.playlist.splice(i, 1)[0]);
                localStorage.setItem('playlist', JSON.stringify({playlist: this.playlist, trackIds: this.trackIds}))
            },
            changeTab(x) {
                this.currentTab = x
            }
        }
    }
</script>
