<template>
    <vue-plyr>
        <audio>
            <source src="audio.mp3" type="audio/mp3"/>
            <source src="audio.ogg" type="audio/ogg"/>
        </audio>
    </vue-plyr>
</template>

<script>
    export default {
        name: "AudioPlayer",
        props: {
            file: {
                type: String,
                default: null
            },
            autoPlay: {
                type: Boolean,
                default: false
            },
            loop: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            audio: undefined,
            currentSeconds: 0,
            durationSeconds: 0,
            innerLoop: false,
            loaded: false,
            playing: false,
            previousVolume: 35,
            showVolume: false,
            volume: 100,
            convertTimeHHMMSS: (val) => {
                let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);

                return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
            }
        }),
        computed: {

            currentTime() {
                return this.convertTimeHHMMSS(this.currentSeconds);
            },
            durationTime() {
                return this.convertTimeHHMMSS(this.durationSeconds);
            },
            percentComplete() {
                return parseInt(this.currentSeconds / this.durationSeconds * 100);
            },
            muted() {
                return this.volume / 100 === 0;
            }
        },
        watch: {
            playing(value) {
                if (value) {
                    return this.audio.play();
                }
                this.audio.pause();
            },
            volume(value) {
                this.showVolume = false;
                this.audio.volume = this.volume / 100;
            }
        },
        methods: {
            download() {
                this.stop();
                window.open(this.file, 'download');
            },
            load() {
                if (this.audio.readyState >= 2) {
                    this.loaded = true;
                    this.durationSeconds = parseInt(this.audio.duration);
                    return this.playing = this.autoPlay;
                }

                throw new Error('Failed to load sound file.');
            },
            mute() {
                if (this.muted) {
                    return this.volume = this.previousVolume;
                }

                this.previousVolume = this.volume;
                this.volume = 0;
            },
            seek(e) {

                const el = e.target.getBoundingClientRect();
                const seekPos = (e.clientX - el.left) / el.width;

                this.audio.currentTime = parseInt(this.audio.duration * seekPos);
            },
            stop() {
                this.playing = false;
                this.audio.currentTime = 0;
            },
            update(e) {
                this.currentSeconds = parseInt(this.audio.currentTime);
            },
            dragCircle(e) {
                const el = this.$refs.dragArea.getBoundingClientRect();
                let seekPos = (e.clientX - el.left) / el.width;
                if (seekPos < 0) {
                    seekPos = 0
                }
                if (seekPos > 1) {
                    seekPos = 1
                }
                this.audio.currentTime = parseInt(this.audio.duration * seekPos);
                document.addEventListener('mousemove', this.moveCircle)
                document.addEventListener('mouseup', this.endDragCircle)
            },
            endDragCircle() {
                document.removeEventListener('mousemove', this.moveCircle)
                document.removeEventListener('mouseup', this.endDragCircle)
            },
            moveCircle(e) {
                const el = this.$refs.dragArea.getBoundingClientRect();
                let seekPos = (e.clientX - el.left) / el.width;
                if (seekPos < 0) {
                    seekPos = 0
                }
                if (seekPos > 1) {
                    seekPos = 1
                }
                this.audio.currentTime = parseInt(this.audio.duration * seekPos);
            }
        },
        created() {
            this.innerLoop = this.loop;
        },
        mounted() {
            this.audio = this.$el.querySelectorAll('audio')[0];
            this.audio.addEventListener('timeupdate', this.update);
            this.audio.addEventListener('loadeddata', this.load);
            this.audio.addEventListener('pause', () => {
                this.playing = false;
            });
            this.audio.addEventListener('play', () => {
                this.playing = true;
            });
        }
    }
</script>

<style scoped>


</style>