<template>
    <div class="main-player__wrap"
         :class="{'show':Object.entries(playlist).length !== 0,'min':playerState==='hide','full':playerState==='full'}">
        <div class="main-player__controls">
            <span @click="backward">
                <svg width="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="backward" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                     class="svg-inline--fa fa-backward fa-w-16"><path fill="currentColor"
                                                                      d="M11.5 280.6l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2zm256 0l192 160c20.6 17.2 52.5 2.8 52.5-24.6V96c0-27.4-31.9-41.8-52.5-24.6l-192 160c-15.3 12.8-15.3 36.4 0 49.2z"
                                                                      class=""></path></svg>
            </span>
            <span @click="forward">
                <svg width="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="forward" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-forward fa-w-16"><path
                    fill="currentColor"
                    d="M500.5 231.4l-192-160C287.9 54.3 256 68.6 256 96v320c0 27.4 31.9 41.8 52.5 24.6l192-160c15.3-12.8 15.3-36.4 0-49.2zm-256 0l-192-160C31.9 54.3 0 68.6 0 96v320c0 27.4 31.9 41.8 52.5 24.6l192-160c15.3-12.8 15.3-36.4 0-49.2z"
                    class=""></path></svg>
            </span>
            <span @click="shuffle" title="Перемешать">
                <svg width="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="random" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-random fa-w-16"><path
                    fill="currentColor"
                    d="M504.971 359.029c9.373 9.373 9.373 24.569 0 33.941l-80 79.984c-15.01 15.01-40.971 4.49-40.971-16.971V416h-58.785a12.004 12.004 0 0 1-8.773-3.812l-70.556-75.596 53.333-57.143L352 336h32v-39.981c0-21.438 25.943-31.998 40.971-16.971l80 79.981zM12 176h84l52.781 56.551 53.333-57.143-70.556-75.596A11.999 11.999 0 0 0 122.785 96H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12zm372 0v39.984c0 21.46 25.961 31.98 40.971 16.971l80-79.984c9.373-9.373 9.373-24.569 0-33.941l-80-79.981C409.943 24.021 384 34.582 384 56.019V96h-58.785a12.004 12.004 0 0 0-8.773 3.812L96 336H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h110.785c3.326 0 6.503-1.381 8.773-3.812L352 176h32z"
                    class=""></path></svg>
            </span>
            <span class="redo" @click="redo=!redo" :class="{'active':redo}" title="Повторять плейлист">
                <svg width="20" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="redo-alt" role="img"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                     class="svg-inline--fa fa-redo-alt fa-w-16"><path fill="currentColor"
                                                                      d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z"
                                                                      class=""></path></svg>
            </span>
            <span @click="playerState='full'" :class="{'active':playerState==='full'}" title="На весь экран">
<svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="24" viewBox="0 0 500.000000 500.000000"
     preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
<path
    d="M600 3654 l0 -746 30 -28 c90 -84 251 -55 301 53 17 40 19 73 19 580 l0 537 537 0 c526 0 538 0 581 21 108 53 136 209 52 299 l-28 30 -746 0 -746 0 0 -746z"/>
<path
    d="M2880 4370 c-84 -90 -56 -246 52 -299 43 -21 55 -21 581 -21 l537 0 0 -537 c0 -507 2 -540 19 -580 50 -108 211 -137 301 -53 l30 28 0 746 0 746 -746 0 -746 0 -28 -30z"/>
<path
    d="M683 2056 c-18 -8 -44 -26 -58 -40 l-25 -25 0 -746 0 -745 746 0 746 0 28 30 c84 90 56 246 -52 299 -43 21 -55 21 -581 21 l-537 0 0 538 c0 610 1 594 -80 648 -51 34 -137 43 -187 20z"/>
<path
    d="M4171 2059 c-46 -18 -84 -53 -102 -93 -17 -38 -19 -76 -19 -578 l0 -538 -537 0 c-526 0 -538 0 -581 -21 -108 -53 -136 -209 -52 -299 l28 -30 746 0 746 0 0 745 0 746 -25 25 c-48 48 -141 68 -204 43z"/>
</g>
</svg>
            </span>
            <span @click="share" title="Поделиться">
                <svg width="20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M503.7 226.2l-176 151.1c-15.38 13.3-39.69 2.545-39.69-18.16V272.1C132.9 274.3 66.06 312.8 111.4 457.8c5.031 16.09-14.41 28.56-28.06 18.62C39.59 444.6 0 383.8 0 322.3c0-152.2 127.4-184.4 288-186.3V56.02c0-20.67 24.28-31.46 39.69-18.16l176 151.1C514.8 199.4 514.8 216.6 503.7 226.2z"/></svg>
            </span>
            <span @click="playerState='normal'" :class="{'active':playerState==='normal'}"
                  v-html='"<svg width=\"20\" aria-hidden=\"true\" focusable=\"false\" data-prefix=\"fal\" data-icon=\"window-maximize\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 512 512\" class=\"svg-inline--fa fa-window-maximize fa-w-16\"><path fill=\"currentColor\" d=\"M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm16 400c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V192h448v240zM32 160V80c0-8.8 7.2-16 16-16h416c8.8 0 16 7.2 16 16v80H32z\" class=\"\"></path></svg>"'>

            </span>
            <span @click="playerState='hide'" :class="{'active':playerState==='hide'}" title="Свернуть"
                  v-html='"<svg width=\"20\" aria-hidden=\"true\" focusable=\"false\" data-prefix=\"fas\" data-icon=\"minus\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 448 512\" class=\"svg-inline--fa fa-minus fa-w-14\"><path fill=\"currentColor\" d=\"M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z\" class=\"\"></path></svg>"'>

            </span>
        </div>
        <div class="main-player" v-if="Object.entries(playlist).length !== 0">
            <div class="main-player__name">{{playlist[currentTrack].name}}</div>
            <vue-plyr id="main_player" ref="plyr" :key="playlist[currentTrack].id" @play="isPlay=true"
                      @pause="isPlay=false"
                      @ended="nextTrack"
                      @player="setPlayer"
                      :emit="['play','pause','ended']">
                <audio>
                    <source :src="playlist[currentTrack].url" type="audio/mp3"/>
                </audio>
            </vue-plyr>
        </div>
        <div class="main-player-playlist" v-if="Object.entries(playlist).length !== 0">
            <transition-group name="flip-list">
                <div class="main-player-playlist__item" :class="{'active':currentTrack === index}"
                     v-for="(track,index) in playlist" :key="track.name">
                    <div class="animation" :class="{'show':isPlay}" v-if="currentTrack === index">
                        <div class="loader-3">
                            <div class="item-1"></div>
                            <div class="item-2"></div>
                            <div class="item-3"></div>
                            <div class="item-4"></div>
                            <div class="item-5"></div>
                        </div>
                    </div>
                    <span class="main-player-playlist__item-name" @click="goTo(index)">
{{track.name}}
    </span>
                    <span v-if="index!==playlist.length-1" class="button" style="margin-left:5px"
                          @click="$emit('down', index)">
<svg width="10" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-down" role="img"
     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-down fa-w-14"><path
    fill="currentColor"
    d="M441.9 250.1l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L250 385.4V44c0-6.6-5.4-12-12-12h-28c-6.6 0-12 5.4-12 12v341.4L42.9 230.3c-4.7-4.7-12.3-4.7-17 0L6.1 250.1c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"
    class=""></path></svg>
                    </span>
                    <span v-if="index!==0" class="button" style="margin-left:5px" @click="$emit('up', index)">
                        <svg width="10" aria-hidden="true" focusable="false" data-prefix="far" data-icon="arrow-up"
                             role="img"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                             class="svg-inline--fa fa-arrow-up fa-w-14"><path fill="currentColor"
                                                                              d="M6.101 261.899L25.9 281.698c4.686 4.686 12.284 4.686 16.971 0L198 126.568V468c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12V126.568l155.13 155.13c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 35.515c-4.686-4.686-12.284-4.686-16.971 0L6.101 244.929c-4.687 4.686-4.687 12.284 0 16.97z"
                                                                              class=""></path></svg>
                    </span>
                    <span class="button" style="margin-left:5px" @click="$emit('delete', index)">
                        <svg width="10" focusable="false"
                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor"
                                                                                            d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
                                                                                            class=""></path></svg>
                    </span>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>

    function fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }
    function copyTextToClipboard(text) {
        return new Promise(resolve => {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                resolve()
                return;
            }
            navigator.clipboard.writeText(text).then(function() {
                console.log('Async: Copying to clipboard was successful!');
                resolve()
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        })
    }

    export default {
        name: "MainAudioPlayer",
        props: ['playlist'],
        data() {
            return {
                currentTrack: 0,
                isReady: false,
                isPlay: false,
                player: {},
                redo: false,
                playerState: 'normal'
            }
        },
        computed: {},
        methods: {
            share() {
                const text = 'Плейлист GreenStudio\n' + location.origin + '?playlist=' + encodeURI(localStorage.getItem('playlist'))
                copyTextToClipboard(text)
                    .then(()=>{
                        alert('Ссылка на плейлист была скопирована.')
                    })
            },
            backward() {
                if (this.currentTrack === 0)
                    this.currentTrack = this.playlist.length - 1
                else
                    this.currentTrack = this.currentTrack - 1
            },
            forward() {
                if (this.currentTrack < this.playlist.length - 1)
                    this.currentTrack = this.currentTrack + 1
                else
                    this.currentTrack = 0
            },
            shuffle() {
                this.player.stop()
                this.$emit('shuffle')
            },
            toggleState() {
                this.playerState = !this.playerState
            },
            setPlayer(player) {
                this.player = player
                this.player.play()
            },
            goTo(i) {
                this.currentTrack = i
            },
            nextTrack() {
                if (this.currentTrack < this.playlist.length - 1)
                    this.currentTrack = this.currentTrack + 1
                else if (this.redo)
                    this.currentTrack = 0
            }
        },
    }
</script>

<style lang="scss" scoped>
    .button {
        cursor: pointer;
        display: inline-block;
        padding: 3px
    }

    .flip-list-move {
        transition: transform 1s;
    }

    .main-player__wrap {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background: #fff;
        @at-root .eco-theme & {
            background: #000;
            color: #a7a7a7;
        }
        z-index: 9999;
        transition: bottom ease-in-out .3s;

        &.full {
            height: calc(100% - 100px);
        }

        &.show {
            bottom: 0
        }

        &.active {
            bottom: 0
        }
    }

    .main-player {
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        @at-root .eco-theme & {
            border-top: 1px solid #777;
            border-bottom: 1px solid #777;
        }
        position: relative;
        @at-root .min & {
            max-height: 0;
            padding: 0;
            overflow: hidden;
        }
        &__controls {
            border: 1px solid #e2e2e2;
            border-bottom: 0;
            border-left: 0;
            background: #fff;
            position: absolute;
            bottom: 100%;
            left: 0;
            font-size: 0;
            @at-root .eco-theme & {
                background: #000;
                color: #a7a7a7;
                border: 1px solid #777;
            }

            span {
                cursor: pointer;
                width: 45px;
                height: 45px;
                text-align: center;
                line-height: 45px;
                font-size: 14px;
                display: inline-block;
                transition: background-color ease .3s;

                &:hover, &.active {
                    background: #22b14c;
                }

                &:first-child {
                    border-right: 1px solid #e2e2e2;
                    @at-root .eco-theme & {
                        border-right: 1px solid #777;
                    }
                }
            }
        }

        &__name {
            font-size: 16px;
            padding: 10px;
            cursor: pointer;
        }
    }

    .main-player-playlist {
        width: 100%;
        padding: 10px;
        @at-root .full & {
            max-height: 100%;
        }
        max-height: 90px;
        overflow-y: auto;
        @at-root .min & {
            max-height: 0;
            padding: 0;
            overflow: hidden;
        }
        &__item {
            font-size: 16px;
            padding: 5px 0;

            &.active {
                font-weight: bold;
            }
        }
    }

    .animation {
        position: relative;
        width: 0;
        overflow: hidden;
        height: 20px;
        display: inline-block;
        transition: width ease .3s;

        &.show {
            width: 40px
        }
    }

    .loader-3 {
        width: 40px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -5px auto 0;
        font-size: 0;
        text-align: center;
    }

    .loader-3 div {
        background-color: #000;
        @at-root .eco-theme & {
            background-color: #a7a7a7;
        }
        margin-left: 2px;
        height: 100%;
        width: 2px;
        display: inline-block;
        animation: loader-3 1s infinite ease-in-out;
    }

    .loader-3 .item-2 {
        animation-delay: -0.8s;
    }

    .loader-3 .item-3 {
        animation-delay: -0.4s;
    }

    .loader-3 .item-4 {
        animation-delay: -0.6s;
    }

    .loader-3 .item-5 {
        animation-delay: -0.2s;
    }

    @keyframes loader-3 {
        0%,
        40% {
            transform: scaleY(0.4);
        }
        100% {
            transform: scaleY(0.6);
        }
        20% {
            transform: scaleY(1.0);
        }
    }
</style>
